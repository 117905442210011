@media only screen and (min-width: 600px) {
  .MuiCardMedia-img {
    height: "100px";
  }
}

@media only screen and (min-width: 768px) {
  .MuiCardMedia-img {
    height: "300px";
  }
}

@media only screen and (min-width: 1080px) {
  .MuiCardMedia-img {
    height: "600px";
  }
}
